import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
    <Layout sermonSrc="https://www.youtube.com/embed/fgtAOUk0a4w">
        <SEO title="What's the Big Deal About Wanting Something? - The Big 10" />
    </Layout>
)

export default SermonPost
